import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { navigate } from "gatsby"
import useAuth from "../hooks/useAuth"

const AuthWrapper = ({ children }) => {
  const { isAuthenticated } = useAuth()
  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/login")
    }
  }, [isAuthenticated])
  return <>{children}</>
}

AuthWrapper.propTypes = {
  children: PropTypes.node.isRequired,
}

export default AuthWrapper
