import { useStaticQuery, graphql, Link } from "gatsby"
import React from "react"
import styled from "styled-components"
import logo from "../images/logo.png"
import useAuth from "../hooks/useAuth"
import Dropdown from "./dropdown"

const StyledHeader = styled.header`
  background: ${(props) => props.background || "#2b7ee4"};
`

const Header = () => {
  const { logout, isAuthenticated } = useAuth()
  const handleLogout = (e) => {
    e.preventDefault()
    logout()
  }
  const data = useStaticQuery(graphql`
    query NavigationQuery {
      strapiTopNavigation {
        id
        Background
        Logo
        Buttons {
          Text
          URL
          id
        }
        Dropdowns {
          Text
          id
          Items {
            Text
            URL
            id
          }
        }
      }
    }
  `)
  return (
    <StyledHeader
      className="level"
      background={data.strapiTopNavigation.Background}
    >
      <div className="level-item has-text-centered">
        <Link to="/" className="is-pulled-left">
          <img
            src={
              data.strapiTopNavigation.Logo != null
                ? data.strapiTopNavigation.Logo
                : logo
            }
            alt="logo of person laying on their back reading a book text says Hound Dog Reading we make reading easy"
          />
        </Link>
      </div>

      <div className="level-item has-text-centered">
        <div className="level">
          {isAuthenticated ? (
            <>
              {data.strapiTopNavigation.Dropdowns.map((dropdown) => (
                <Dropdown key={dropdown.id} text={dropdown.Text} items={dropdown.Items} />
              ))}
              {data.strapiTopNavigation.Buttons.map((button) => (
                <Link
                  key={button.id}
                  to={button.URL}
                  className="level-item has-text-centered m-1"
                >
                  <button className="button">{button.Text}</button>
                </Link>
              ))}
              <Link
                to="/"
                onClick={handleLogout}
                className="level-item has-text-centered m-1"
              >
                <button className="button">Logout</button>
              </Link>
            </>
          ) : (
            <Link to="/login" className="level-item has-text-centered m-1">
              <button className="button">Login</button>
            </Link>
          )}
        </div>
      </div>
    </StyledHeader>
  )
}

export default Header
