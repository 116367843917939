import React from "react"
import PropTypes from "prop-types"
import { FaAngleDown } from "react-icons/fa"
import { Link } from "gatsby"

const Dropdown = (props) => (
  <div className="dropdown is-hoverable m-1">
    <div className="dropdown-trigger">
      <button
        className="button"
        aria-haspopup="true"
        aria-controls="dropdown-menu"
      >
        <span>{props.text}</span>
        <span className="icon is-small">
          <FaAngleDown aria-hidden="true" />
        </span>
      </button>
    </div>
    <div className="dropdown-menu" role="menu">
      <div className="dropdown-content has-text-left">
        {props.items.map((item) => {
          if (item.Text === "-----") {
            return <hr key={item.id} className="dropdown-divider"></hr>
          } else {
            return (
              <Link key={item.id} to={(item.URL === undefined) ? "#" : item.URL} className="dropdown-item">
                {item.Text}
              </Link>
            )
          }
        })}
      </div>
    </div>
  </div>
)

Dropdown.propTypes = {
  text: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
}

export default Dropdown
